
@font-face {  
  font-family: 'Avenir-regular';  
  font-display: swap; 
  src: local('Avenir-regular'), url('./assets/fonts/Avenir.woff') format('woff');
}

@font-face {  
  font-family: 'Bold-titles';  
  font-display: swap;  
  src: local('Bold-titles'),  url('./assets/fonts/Nexa-Bold.woff') format('woff');
}

:root {
    --blue-one:#345F81;
    --white:#ffffff;
    --green-one:#50c4ac;
    --linkedin-color:#0A66C2;
    --font-regular-text:'Avenir-regular';
    --font-bold-titles:'Bold-titles';
  }